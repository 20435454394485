<template>
  <section class="section-services-content pt-5" :style="`background-color: var(--white)`">
    <div class="page-padding">
      <div class="container-large">
        <div class="padding-bottom padding-huge">
          <div class="d-flex flex-row justify-content-center w-100 pb-5">
            <h2 class="heading-medium">{{ vars.titleText }}</h2>
          </div>
          <div id="viewagents-widget" data-key="39b2a9ee19a84d2ba9a8e78cf5279b7a"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Theme2ViewAgents',

  mixins: [ComponentMixin],

  head() {
    return {
      script: [
        {
          src: 'https://widget.viewagents.com/api/Widget.js',
          key: 'viewagents',
          body: true,
          async: false,
        },
      ],
    };
  },
});
</script>
