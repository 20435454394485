<template>
  <section
    class="section-home-blog tw-pt-16 md:tw-pt-0"
    :style="`background-color: var(--${backgroundColor}) !important`"
  >
    <div class="page-padding">
      <div class="container-large">
        <div class="padding-vertical padding-xhuge" style="padding-bottom: 0">
          <div class="top-blog-content tw-flex tw-flex-col md:tw-flex-row">
            <div class="max-width-large">
              <h2 class="heading-medium">{{ title }}</h2>
            </div>
            <div class="w-100">
              <search-box
                v-model="keyword"
                :search-result-list="[]"
                :placeholder="searchBoxPlaceholder"
                :search-method="onSearched"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import type { Component } from '~/types';
import IndexGlobal from '~/mixins/index.global';
import SearchBox from '~/components/common/SearchBox.vue';

export default defineNuxtComponent({
  name: 'BlogsTopBar',
  components: { SearchBox },

  mixins: [IndexGlobal],

  props: {
    component: {
      required: true,
      type: Object as PropType<Component>,
    },
  },

  data() {
    return {
      keyword: '',
    };
  },

  mounted() {
    const route = useRoute();
    if (route.query.q) {
      this.keyword = route.query.q as string;
    }
  },

  methods: {
    async onSearched() {
      const router = useRouter();
      if (this.keyword && this.keyword.toString().trim()) {
        await router.push({
          query: {
            q: this.keyword,
          },
        });
      } else {
        await router.push({});
      }
    },
  },

  computed: {
    title() {
      return getVariable(this.component, 'primary_title-text');
    },

    searchBoxPlaceholder(): string {
      return getVariable(this.component, 'search_box_label-text') as string;
    },

    backgroundColor() {
      return getVariable(this.component, 'background-color');
    },
  },
});
</script>

<style scoped></style>
